export default {
    name: 'instagram',
    component() {
        return {
            error: false,
            results: [],
            accessToken: "IGQWRNSm1QME9Cazl5S1BoM0kzbC11UEs5bVVRRmxiUDUwckdaUGVoOGxxYS03QTIwTjQ1OC1PeVU2ZAjh4WHRDSHV3cG0xNnpYT2VhMWRoeFBIaU9SLUtDTnhULTZAYUlBmZA0hKcnE0WUFaVGhJZA1VVVWtzamdHMkUZD",
            accessTokenMel: "IGQWRPZAEh3MUlpcnJUbEtOZAlNfWUpiSXlncEJlbFRhd0dMSGRQVkVYaWkyNGZA5eC1vOEFiMHVuOGtPWkEzRzJmZAC1YNk1oa1czRGVKWWhYdGYyUHBFZA2Y1RUxJMW1oR1I0ZAGp0OVFERXotSXp3dXR4dXlJUldMVHMZD",
           
            init() {
                // this.getInstagramFeed();
            },

            getInstagramFeed() {
                fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,media_type,media_url,permalink,timestamp&limit=10&access_token=${this.accessToken}`)
                .then(response => response.json())
                .then(data => {
                    if (data.error) {                    
                        this.error = true;
                    } else {
                        this.results = data.data;
                    }
                })
                .catch(error => {
                    this.error = true;
                });
            },

            getInstagramFeedMel() {
                fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,media_type,media_url,permalink,timestamp&limit=10&access_token=${this.accessTokenMel}`)
                .then(response => response.json())
                .then(data => {
                    if (data.error) {                    
                        this.error = true;
                    } else {
                        this.results = data.data;
                    }
                })
                .catch(error => {
                    this.error = true;
                });
            }
        }
    }
}